import React, { useState } from 'react'
import styles from './ReferralModal.module.css'
import { APCodeBox } from '../../APDS/APCodeBox/APCodeBox'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { APButtonSecondary } from '../../APDS/APButtonSecondary/APButtonSecondary'
import { APTextbox } from '../../APDS/APTextbox/APTextbox'
import { useRefCode } from '../../Hooks/useRefCode'
import { useCustomAlert } from '../../Hooks/useCustomAlert'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'

export const ReferralCodeModal: React.FC = () => {
	const { refCode, confirmRefCodeLoading, confirmRefCode } = useRefCode()
	const { sendSuccessAlert } = useCustomAlert()
	const { modal, closeCurrentModal } = useModalControl()

	const [quantity, setQuantity] = useState<number>(1)

	const handleCopy = () => {
		navigator.clipboard.writeText(refCode)
		sendSuccessAlert('Referral code copied to clipboard!')
	}

	const handleClose = () => {
		closeCurrentModal()
	}

	const handleConfirm = async () => {
		console.log('confirmRefCode', quantity, refCode)
		await confirmRefCode(quantity, refCode)
		handleClose()
	}

	const handleInstanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQuantity(parseInt(e.target.value))
	}

	return (
		modal === MODAL_TYPES.REFCODE && (
			<div className={styles.backdrop} onClick={handleClose}>
				<div className={styles.modal} onClick={(e) => e.stopPropagation()}>
					<h2 className={styles.title}>Your referral code has been generated</h2>
					<div className={styles.referralCodeContainer}>
						<APCodeBox code={refCode} onCopy={handleCopy} />
					</div>
					<label className={styles.label} htmlFor="usersCount">
						How many people will use this code?
					</label>
					<APTextbox
						value={quantity}
						id="usersCount"
						className={styles.textbox}
						onChange={handleInstanceChange}
					/>
					<div className={styles.buttonsContainer}>
						<APButtonPrimary
							className={styles.primaryButton}
							onClick={handleConfirm}
							isLoading={confirmRefCodeLoading}
							disabled={confirmRefCodeLoading}>
							Confirm
						</APButtonPrimary>
						<APButtonSecondary className={styles.secondaryButton} onClick={handleClose}>
							Cancel
						</APButtonSecondary>
					</div>
				</div>
			</div>
		)
	)
}
