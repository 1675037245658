import React, { useEffect, useState } from 'react'
import { APProduct } from '../../APDS'
import styles from './Home.module.css'
import { Header } from '../../Components/Header/Header'
import { APSelect } from '../../APDS/APSelect'
import { APSlider } from '../../APDS/APSlider'
import { useProducts } from '../../Hooks/useProducts'
import { Brand } from '../../Types/Brand'
import { Gender } from '../../Types/Gender'
import { useStringUtils } from '../../Hooks/useStringUtils'

export const Home = () => {
	const { capitalizeFirstLetter } = useStringUtils()
	const {
		priceRange,
		isLoading,
		brands,
		products,
		genders,
		brand,
		gender,
		categoryFilters,
		selectedCategory,
		getInitialBrandsAndProducts,
		changeBrand,
		changeGender,
		changeCategory,
		changePrice
	} = useProducts()

	// State for slider (price filter)
	useEffect(() => {
		getInitialBrandsAndProducts()
	}, [])

	const handleBrandChange = (e: { target: { value: string } }) => {
		changeBrand(e.target.value)
	}

	const handleChangeGender = (e: { target: { value: string } }) => {
		changeGender(e.target.value as Gender)
	}

	const handleChangeCategory = (e: { target: { value: string } }) => {
		changeCategory(parseInt(e.target.value))
	}

	const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		changePrice(parseInt(event.target.value))
	}

	return (
		<div className={styles.container}>
			<Header />
			<div className={styles.filters}>
				<div>
					<p className={styles.filterDescriptor}>MAX PRICE</p>
					<APSlider
						min={priceRange.min}
						max={priceRange.max}
						step={10}
						value={priceRange.currentValue}
						onChange={handlePriceChange}
					/>
				</div>
				<div>
					<p className={styles.filterDescriptor}>CATEGORY</p>
					<APSelect
						className={styles.filterButton}
						onChange={handleChangeCategory}
						value={selectedCategory}>
						<option className={styles.filterOption} value={-1}>
							All categories
						</option>
						{categoryFilters?.map((category) => (
							<option key={category.id} value={category.id}>
								{category.seoKeyword}
							</option>
						))}
					</APSelect>
				</div>
				<div>
					<p className={styles.filterDescriptor}>GENDER</p>

					<APSelect className={styles.filterButton} onChange={handleChangeGender} value={gender}>
						{genders?.map((gender: Gender) => (
							<option key={gender} value={gender}>
								{capitalizeFirstLetter(gender)}
							</option>
						))}
					</APSelect>
				</div>
				<div>
					<p className={styles.filterDescriptor}>BRAND</p>

					<APSelect className={styles.filterButton} onChange={handleBrandChange} value={brand}>
						{brands?.map((brand: Brand) => (
							<option key={brand.id} value={brand.seoKeyword}>
								{brand.name}
							</option>
						))}
					</APSelect>
				</div>
			</div>
			<div className={styles.productGrid}>
				{!isLoading && (!products || products.length === 0) ? (
					<p className={styles.noProducts}>There are no products that satisfy these filters</p>
				) : (
					(isLoading ? Array(12).fill(null) : products || []).map((product) => (
						<APProduct
							skeleton={isLoading}
							key={product?.id}
							image={product?.imageUrl}
							productUrl={product?.productUrl}
							brand={product?.brand}
							name={product?.name}
							originalPrice={product?.regularPrice}
							salePrice={product?.priceAmount}
							exclusive={true}
							source={product?.source}
						/>
					))
				)}
			</div>
		</div>
	)
}
