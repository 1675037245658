import { useDispatch, useSelector } from 'react-redux'
import { auth } from '../Firebase/firebase'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth'
import { setUid, login, setToken, logout, setEmail } from '../Redux/accountSlice'
import { useApiClient } from './useApiClient'
import { useCustomAlert } from './useCustomAlert'
import { FirebaseError } from 'firebase/app';
import { useModalControl } from './useModalControl'
import { APState } from '../Types'
import { useAccount } from './useAccount'
import { MODAL_TYPES } from '../Types/Modal'

interface ErrorMessage {
    code: string;
    message: string;
}

const errorMessages: Record<string, string> = {
    'auth/weak-password': 'Password should be at least 6 characters long.',
    'auth/email-already-in-use': 'An account with this email already exists.',
    'auth/invalid-email': 'Please enter a valid email address.',
    'auth/user-not-found': 'No account found with this email.',
    'auth/wrong-password': 'Incorrect password. Please try again.',
    // Add more error codes and messages as needed
};

const getFirebaseErrorMessage = (error: unknown): ErrorMessage => {
    if (error instanceof FirebaseError) {
        return {
            code: error.code,
            message: errorMessages[error.code] || error.message
        };
    }

    // For non-Firebase errors or unknown errors
    return {
        code: 'unknown',
        message: error instanceof Error ? error.message : 'An unknown error occurred.'
    };
}


const useAuth = () => {
    const isLoggedIn = useSelector((state: APState) => state.account.isLoggedIn)
    const dispatch = useDispatch();
    const { switchModal } = useModalControl();
    const { checkTopOff } = useAccount()
    const { checkCode, createAccount } = useApiClient()
    const { sendErrorAlert } = useCustomAlert()
    const { closeCurrentModal } = useModalControl();

    const persistLogin = () => {
        const unsubcribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const accessToken = await user.getIdToken();
                dispatch(setUid(user.uid))
                dispatch(setToken(accessToken))
                dispatch(setEmail(user.email))
                dispatch(login())
                const isToppedOff = await checkTopOff(accessToken)
                if (!isToppedOff) {
                    switchModal(MODAL_TYPES.SUBSCRIPTION)
                }
            } else {
                dispatch(setUid(''))
                dispatch(setToken(''))
                dispatch(setEmail(''))
                dispatch(logout())
            }
        });
        return unsubcribe;
    }

    const signUp = async (accessCode: string, email: string, password: string) => {
        const response = await checkCode(accessCode)

        if (response === 'OK') {
            try {
                await createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
                    const user = userCredential.user;
                    const accessToken = await user.getIdToken();
                    dispatch(setUid(userCredential.user.uid))
                    dispatch(setToken(accessToken))
                    dispatch(setEmail(user.email))

                    await createAccount(email, accessCode, accessToken).catch((error) => {
                        dispatch(setUid(''))
                        dispatch(setToken(''))
                        dispatch(setEmail(''))
                        dispatch(logout())
                        sendErrorAlert(getFirebaseErrorMessage(error).message)
                    })

                    closeCurrentModal()

                }).catch((error) => {
                    sendErrorAlert(getFirebaseErrorMessage(error).message)
                });
            } catch (error) {
                const errorMessage = error;
                sendErrorAlert(errorMessage as string)
            }

        }
    }

    const signIn = async (email: string, password: string) => {
        try {
            await signInWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
                const user = userCredential.user;
                const accessToken = await user.getIdToken();
                dispatch(setUid(userCredential.user.uid))
                dispatch(setToken(accessToken))
                dispatch(setEmail(user.email))
                closeCurrentModal()
            }).catch((error) => {
                sendErrorAlert(getFirebaseErrorMessage(error).message)

            });
        } catch (error) {
            sendErrorAlert(error as string)
        }
    }

    const signOut = async () => {
        try {
            await auth.signOut()
            window.location.reload()
        } catch (error) {
            sendErrorAlert(error as string)
        }
    }

    return { isLoggedIn, signUp, signIn, signOut, persistLogin }
}

export default useAuth