import React, { FC, useState } from 'react'
import styles from './LogOutModal.module.css'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import useAuth from '../../Hooks/useAuth'

interface LogOutModalProps {
	className?: string
}

export const LogOutModal: FC<LogOutModalProps> = ({ className }) => {
	const { modal, closeCurrentModal } = useModalControl()
	const { signOut } = useAuth()

	const handleClose = () => {
		closeCurrentModal()
	}

	const handleLogOut = () => {
		signOut()
		closeCurrentModal()
	}

	return (
		modal === MODAL_TYPES.LOGOUT && (
			<div className={styles.backdrop} onClick={handleClose}>
				<div
					className={`${styles.modal} ${className ? className : ''}`}
					onClick={(e) => e.stopPropagation()}>
					<h2>Are you sure you want to log out?</h2>
					<APButtonPrimary className={styles.primaryButton} onClick={handleLogOut}>
						Log Out
					</APButtonPrimary>
				</div>
			</div>
		)
	)
}
