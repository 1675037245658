import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: [],
        inStoreCredit: 0,
        cartLoading: false,
    },
    reducers: {
        setCart: (state, action) => {
            state.cart = action.payload;
        },
        setCartLoading: (state, action) => {
            state.cartLoading = action.payload
        },
        setInStoreCredit: (state, action) => {
            state.inStoreCredit = action.payload
        }
    },
});


export const { setCart, setCartLoading, setInStoreCredit } = cartSlice.actions;
export default cartSlice.reducer;