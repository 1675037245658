import React from 'react'
import styles from './CartModal.module.css'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { useModalControl } from '../../Hooks/useModalControl'
import { useCart } from '../../Hooks/useCart'
import { MODAL_TYPES } from '../../Types/Modal'

interface CartModalProps {
	className?: string
}

export const CartModal: React.FC<CartModalProps> = ({ className }) => {
	const { modal, switchModal, closeCurrentModal } = useModalControl()
	const {
		cart,
		inStoreCredit,
		removeFromCart,
		caclulateTotalOriginalPrice,
		calculateTotalSalePrice,
		calculateTotalSavings
	} = useCart()

	const handleClose = () => {
		closeCurrentModal()
	}

	const handleRemoveItem = (index: number) => {
		const product = cart[index]
		removeFromCart(product.productUrl, product.quantity)
	}

	const handleCheckout = () => {
		switchModal(MODAL_TYPES.SHIPPING)
	}

	return (
		modal === MODAL_TYPES.CART && (
			<div className={styles.backdrop} onClick={handleClose}>
				<div className={`${styles.modal} ${className}`} onClick={(e) => e.stopPropagation()}>
					<h2 className={styles.title}>Cart</h2>
					<div className={styles.cartItemsContainer}>
						{cart.map((item, index) => (
							<div key={index} className={styles.cartItem}>
								<div className={styles.productInfo}>
									<p className={styles.productName}>{item.name}</p>
									<p className={styles.productPrice}>
										<span className={styles.currentPrice}>{item.salePrice}$</span>
										<span className={styles.originalPrice}>{item.regularPrice}$</span>
									</p>
								</div>
								<div className={styles.quantity}>{item.quantity}</div>
								<div className={styles.trashIcon} onClick={() => handleRemoveItem(index)}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 448 512"
										width="16"
										height="16">
										<path d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
									</svg>
								</div>
							</div>
						))}
					</div>
					<div className={styles.divider}></div>
					<div className={styles.cartSummary}>
						<div className={styles.totalRow}>
							<span>Total Before</span>
							<span className={styles.strikethrough}>{caclulateTotalOriginalPrice(cart)}$</span>
						</div>
						<div className={styles.totalRow}>
							<span>Subtotal</span>
							<span>{calculateTotalSalePrice(cart)}$</span>
						</div>
						<div className={styles.totalRow}>
							<span>In store credits</span>
							<span>
								{inStoreCredit}$ (-{Math.min(inStoreCredit, calculateTotalSalePrice(cart))}$)
							</span>
						</div>
						<div className={styles.totalRow}>
							<span>Total</span>
							<span>{Math.max(calculateTotalSalePrice(cart) - inStoreCredit, 0)}$</span>
						</div>
						<div className={styles.totalRow}>
							<span>Total Saved</span>
							<span className={styles.saved}>{calculateTotalSavings(cart, inStoreCredit)}$</span>
						</div>
					</div>
					<APButtonPrimary className={styles.checkoutButton} onClick={handleCheckout}>
						Checkout
					</APButtonPrimary>
				</div>
			</div>
		)
	)
}
