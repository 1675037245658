import React, { useEffect } from 'react'
import APBoardVariant from '../../APDS/APBoardVariant/APBoardVariant'
import styles from './Orders.module.css'
import useAuth from '../../Hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { useOrders } from '../../Hooks/useOrders'

const Orders: React.FC = () => {
	const { isLoggedIn } = useAuth()
	const navigate = useNavigate()
	const { ordersLoading, orders, getUserOrders } = useOrders()

	const columns = ['ID', 'Date', 'Amount', 'Method']
	useEffect(() => {
		if (isLoggedIn === false) {
			navigate('/')
		} else if (isLoggedIn) {
			getUserOrders()
		}
	}, [isLoggedIn])
	return (
		<div className={styles.pageWrapper}>
			<a href="/" className={styles.back}>
				&#8592; Back
			</a>
			<h1 className={styles.pageHeader}>Orders</h1>
			<p className={styles.totalOrders}>Total orders: {orders.length}</p>

			<div className={styles.boardWrapper}>
				<APBoardVariant orders={orders} columns={columns} skeleton={ordersLoading} />
			</div>

			<div className={styles.footerMessage}>
				If you&#39;ve experienced any issues receiving your order, please contact us at
				support@armoireprivee.com
			</div>
		</div>
	)
}

export default Orders
