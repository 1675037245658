import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { removeMessage } from '../../Redux/alertSlice'
import { APState } from '../../Types'

export function CustomAlert() {
	const dispatch = useDispatch()
	const snackbar = useSelector((state: APState) => state.alert)

	const handleClose = () => {
		dispatch(removeMessage())
	}

	return (
		<Snackbar
			open={snackbar.open}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
			<MuiAlert
				onClose={handleClose}
				severity={snackbar.severity}
				sx={{ width: '100%' }}
				variant="filled"
				elevation={6}>
				{snackbar.message}
			</MuiAlert>
		</Snackbar>
	)
}

export default CustomAlert
