import React from 'react'
import { SignUpModal } from '../ModalSignUp'
import { SignInModal } from '../ModalSignIn'
import { ContactModal } from '../ModalContact'
import { CartModal } from '../ModalCart'
import { FetchingSizesModal } from '../ModalFetchingSizes'
import { ItemAddedModal } from '../ModalItemsAdded'
import { ReferralCodeModal } from '../ModalReferral'
import { SizeSelectionModal } from '../ModalSizeSelection'
import { SubscriptionModal } from '../ModalSubscription'
import { ShippingInfoModal } from '../ModalShippingInfo'
import { LogOutModal } from '../ModalLogOut'
import { ConfirmUnsubModal } from '../ModalConfirmUnsub'
import { ConfirmDeleteModal } from '../ModalConfirmDelete'

const ModalControl = () => {
	return (
		<>
			<SignUpModal />
			<SignInModal />
			<ContactModal />
			<CartModal />
			<FetchingSizesModal />
			<ItemAddedModal />
			<ReferralCodeModal />
			<SizeSelectionModal />
			<SubscriptionModal />
			<ShippingInfoModal />
			<LogOutModal />
			<ConfirmUnsubModal />
			<ConfirmDeleteModal />
		</>
	)
}

export default ModalControl
