import React from 'react';
import styles from './APSlider.module.css';

interface APSliderProps {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const APSlider: React.FC<APSliderProps> = ({ min, max, step, value, onChange }) => {
  return (
    <div className={styles.sliderWrapper}>
      {/* Display price within the box */}
      <div className={styles.sliderPrice}>
        <span>${value}</span>
      </div>
      {/* Slider for adjusting the price */}
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        className={styles.slider}
      />
    </div>
  );
};
