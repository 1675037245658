
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useApiClient } from './useApiClient';
import { STRIPE_KEY } from '../Main/constants';
import { useState } from 'react';
import { useCustomAlert } from './useCustomAlert';
import { CartItem } from '../Types';

export const useStripe = () => {
    let stripePromse: Promise<Stripe | null>;
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const { sendErrorAlert } = useCustomAlert()
    const { createProductSession, createSubscriptionSession } = useApiClient();

    const getStripe = async () => {
        if (!stripePromse) {
            stripePromse = loadStripe(STRIPE_KEY);
        }
        return stripePromse;
    };

    const redirectToProductCheckout = async (items: CartItem[], inStoreCredit: number) => {
        setCheckoutLoading(true);
        let session = { id: '' };
        await createProductSession(items, inStoreCredit).then(async (res) => {
            const data: { id: string } = await res.json();
            session = data;
        });
        const stripe = await getStripe();
        if (stripe) {
            const { error } = await stripe.redirectToCheckout({ sessionId: session.id || '' });
            sendErrorAlert(error?.message || 'An error occurred');
        }
        setCheckoutLoading(false);
    };

    const redirectToSubscriptionCheckout = async (cycle: string) => {
        setCheckoutLoading(true);
        let session = { id: '' };
        await createSubscriptionSession(cycle).then(async (res) => {
            const data: { id: string } = await res.json();
            session = data;
        });
        const stripe = await getStripe();
        if (stripe) {
            const { error } = await stripe.redirectToCheckout({ sessionId: session.id || '' });
            sendErrorAlert(error?.message || 'An error occurred');
        }
        setCheckoutLoading(false);
    };
    return {
        checkoutLoading,
        redirectToProductCheckout,
        redirectToSubscriptionCheckout,
    };
};
