import React, { FC, useState } from 'react'
import styles from './SignInModal.module.css'
import { APTextbox } from '../../APDS/APTextbox/APTextbox' // Adjust paths as needed
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { APButtonSecondary } from '../../APDS/APButtonSecondary/APButtonSecondary'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import useAuth from '../../Hooks/useAuth'

interface SignInModalProps {
	className?: string
}

export const SignInModal: FC<SignInModalProps> = ({ className }) => {
	const { modal, closeCurrentModal, switchModal } = useModalControl()
	const { signIn } = useAuth()

	const [isLoading, setIsLoading] = useState(false)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const handleClose = () => {
		closeCurrentModal()
	}

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value)
	}

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value)
	}

	const handleSignIn = async () => {
		setIsLoading(true)
		await signIn(email, password)
		setIsLoading(false)
	}

	const goTosignUp = () => {
		switchModal(MODAL_TYPES.REGISTER)
	}

	return (
		modal === MODAL_TYPES.LOGIN && (
			<div className={styles.backdrop} onClick={handleClose}>
				<div
					className={`${styles.modal} ${className ? className : ''}`}
					onClick={(e) => e.stopPropagation()}>
					<h2>Sign In</h2>
					<APTextbox placeholder="Email" onChange={handleEmailChange} />
					<APTextbox placeholder="Password" type="password" onChange={handlePasswordChange} />
					<APButtonPrimary
						className={styles.primaryButton}
						onClick={handleSignIn}
						disabled={isLoading}
						isLoading={isLoading}>
						Sign In
					</APButtonPrimary>
					<APButtonSecondary className={styles.secondaryButton} onClick={goTosignUp}>
						Don’t have an account
					</APButtonSecondary>
				</div>
			</div>
		)
	)
}
