import { useSelector } from "react-redux";
import { APState, CartItem } from "../Types"
import { useCustomAlert } from "../Hooks/useCustomAlert";
import { Gender } from "../Types/Gender";
import { API_HOST } from "../Main/constants";
import { ShippingInfo } from "../Types/ShippingInfo";


export const useApiClient = () => {
	const token = useSelector((state: APState) => state.account.token)
	const uid = useSelector((state: APState) => state.account.uid)
	const email = useSelector((state: APState) => state.account.email)
	const { sendSuccessAlert, sendErrorAlert } = useCustomAlert()
	const host = API_HOST
	return {
		checkCode: async (accessCode: string) => {
			// TODO: adjust to call checkCode endpoint
			try {
				const response = await fetch(`${host}/check-code/${accessCode}`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json'
					}
				})
				const textData = await response.text()
				if (response.status !== 200) {
					sendErrorAlert(textData)
				}
				return textData
			} catch (err) {
				sendErrorAlert(err as string)
			}
		},

		getAccount: async (altToken?: string) => fetch(`${host}/account`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application',
				'Authorization': `Bearer ${token || altToken}`,
			}
		}),

		createAccount: async (email: string, referralCode: string, bearerToken: string) => {
			try {
				const response = await fetch(`${host}/create-account/`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${bearerToken}`
					},
					body: JSON.stringify({ email, referralCode })
				})

				if (response.ok) {
					sendSuccessAlert('Account created successfully')
				} else {
					const textData = await response.text()
					sendErrorAlert(textData)
				}

				return response;
			} catch (err) {
				sendErrorAlert(err as string)
			}
		},

		getFilters: async (genderFilter: Gender) => {
			try {
				const response = await fetch(`${host}/brands/${genderFilter}`)
				try {
					const jsonData = await response.json()
					return jsonData
				} catch {
					sendErrorAlert("An unknown error occured")
					return;
				}

			} catch (err: unknown) {
				if (err instanceof Error) {
					sendErrorAlert(err?.message)
				} else {
					sendErrorAlert("An unknown error occured")
				}
			}
		},

		getProducts: async (brandFilter: string, genderFilter: Gender) => {
			try {
				const response = await fetch(`${host}/designer/${brandFilter}/${genderFilter}`)
				const jsonData = await response.json()
				if (!response.ok) {
					sendErrorAlert("An unknown error occured")
				}
				return jsonData
			} catch (err) {
				sendErrorAlert("An unknown error occured")
				return { products: [], categories: [] }
			}
		},

		getSizes: async (productUrl: string, source?: string) => fetch(`${host}/product?productUrl=${productUrl}&source=${source || 'ssense'}`, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
			}
		}),

		addToCart: async (data: CartItem) => fetch(`${host}/cart/add`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(data)
		}),

		getCart: async () => await fetch(`${host}/cart`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		}),

		getUserReferrals: async () => fetch(`${host}/user-referrals`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application',
				'Authorization': `Bearer ${token}`
			}
		}),

		generateRefCode: async () => fetch(`${host}/generate-unique-code`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		}),

		addUserReferral: async (numberOfInstances: number, code: string, cost = 10) => fetch(`${host}/add-referral`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify({ numberOfInstances, code, cost })
		}),

		createProductSession: async (items: CartItem[], inStoreCredit: number) => fetch(`${host}/stripe/create-product-session`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify({ customer_email: email, items, uid, inStoreCredit })
		}),

		createSubscriptionSession: async (cycle: string) => fetch(`${host}/stripe/create-subscription-session`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify({ customer_email: email, cycle, uid })
		}),

		removeCartItem: async (productUrl: string, quantity: number) => fetch(`${host}/cart/remove`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify({ productUrl, quantity })
		}),

		addShippingInfo: async (data: ShippingInfo) => fetch(`${host}/cart/shipping`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(data)
		}),

		getOrders: async () => fetch(`${host}/account/orders`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application',
				'Authorization': `Bearer ${token}`
			}
		}),

		cancelSubscription: async () => fetch(`${host}/subscription/cancel`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application',
				'Authorization': `Bearer ${token}`
			},
		}),
		deleteAccount: async () => fetch(`${host}/account`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application',
				'Authorization': `Bearer ${token}`
			}
		}),
	}
}
