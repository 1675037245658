import React, { FormEvent, useEffect, useState } from 'react'
import styles from './SizeSelectionModal.module.css'
import { APSelect } from '../../APDS/APSelect/APSelect'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import { useProductInfo } from '../../Hooks/useProductInfo'
import { Size } from '../../Types/Product'
import { useCart } from '../../Hooks/useCart'

export const SizeSelectionModal: React.FC = () => {
	const [quantity, setQuantity] = useState(1)
	const [selectedSize, setSelectedSize] = useState('')

	const { productInfo } = useProductInfo()
	const { addItemToCart, addToCartLoading } = useCart()
	const { modal, closeCurrentModal } = useModalControl()

	useEffect(() => {
		setSelectedSize(productInfo?.sizes[0].size)
	}, [])

	const handleClose = () => {
		closeCurrentModal()
	}

	const handleSizeChange = (e: { target: { value: React.SetStateAction<string> } }) => {
		setSelectedSize(e.target.value)
	}

	const handleAddToCart = () => {
		addItemToCart({
			name: productInfo.name,
			imageUrl: productInfo.imageUrl,
			regularPrice: productInfo.regularPrice,
			productUrl: productInfo.productUrl,
			salePrice: productInfo.priceAmount,
			quantity,
			size: selectedSize
		})
	}

	const handleDecrementQuantity = () => {
		if (quantity > 1) {
			setQuantity(quantity - 1)
		}
	}

	const handleIncrementQuantity = () => {
		const maxQuantity = productInfo.sizes.find((size) => size.size === selectedSize)?.lowStock || 1
		if (quantity < maxQuantity) {
			setQuantity(quantity + 1)
		}
	}

	return (
		modal === MODAL_TYPES.SIZE_SELECTION && (
			<div className={styles.backdrop} onClick={handleClose}>
				<div className={styles.modal} onClick={(e) => e.stopPropagation()}>
					<h2 className={styles.title}>{productInfo.name}</h2>
					<img src={productInfo.imageUrl} alt={productInfo.name} className={styles.image} />
					<div className={styles.selectWrapper}>
						<label className={styles.label} htmlFor="size">
							size
						</label>
						<APSelect id="size" className={styles.select} onChange={handleSizeChange}>
							{productInfo?.sizes?.map((size: Size) => (
								<option key={size.size} value={size.size}>
									{size.size}
								</option>
							))}
						</APSelect>
					</div>
					<div className={styles.unitsWrapper}>
						<label className={styles.label} htmlFor="units">
							units
						</label>
						<div className={styles.unitControl}>
							<button className={styles.unitButton} onClick={handleDecrementQuantity}>
								&lt;
							</button>
							<span className={styles.unitDisplay}>{quantity}</span>
							<button className={styles.unitButton} onClick={handleIncrementQuantity}>
								&gt;
							</button>
						</div>
					</div>
					<APButtonPrimary
						onClick={handleAddToCart}
						className={styles.addToCartButton}
						isLoading={addToCartLoading}
						disabled={addToCartLoading}>
						Add to Cart
					</APButtonPrimary>
				</div>
			</div>
		)
	)
}
