import React from 'react'
import styles from './APCodeBox.module.css'
import { APCopyIcon } from '../APCopyIcon/APCopyIcon'

interface APCodeBoxProps {
	code: string
	onCopy?: () => void
	className?: string
}

export const APCodeBox: React.FC<APCodeBoxProps> = ({ code, onCopy, className }) => {
	return (
		<div className={`${styles.codeBoxContainer} ${className || ''}`}>
			<APCopyIcon onClick={onCopy} />
			<input className={styles.codeBox} value={code} readOnly />
		</div>
	)
}
