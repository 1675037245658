// Define the MODALTYPES constant
export const MODAL_TYPES = {
    LOGIN: "login",
    REGISTER: "register",
    CART: "cart",
    SHIPPING: "shipping",
    SUBSCRIPTION: "subscription",
    CONTACT: "contact",
    ITEMS_ADDED: "itemsAdded",
    FETCHING_SIZES: "fetchingSizes",
    REFCODE: "refcode",
    NOSIZES: "nosizes",
    SIZE_SELECTION: "sizeSelection",
    LOGOUT: "logout",
    CANCEL_SUBSCRIPTION: "cancelSubscription",
    DELETE_ACCOUNT: "deleteAccount",
} as const;

// Create a type from the values of MODALTYPES
type ModalType = typeof MODAL_TYPES[keyof typeof MODAL_TYPES];

// Update the Modal interface to use the new ModalType
export interface Modal {
    openModal: ModalType | null;
    payload: unknown | null;
}

export type OpenModalType = ModalType | null;
export type OpenModalPayload = unknown | null;