import React from 'react'
import styles from './APBoard.module.css'
import { Skeleton } from '@mui/material'

interface APBoardProps {
	columns: string[]
	data?: string[][]
	className?: string
	skeleton?: boolean
}

export const APBoard: React.FC<APBoardProps> = ({ columns, data, className, skeleton }) => {
	return (
		<div className={`${styles.board} ${className || ''}`}>
			<div className={styles.header}>
				{columns.map((column, index) => (
					<div key={index} className={styles.columnHeader}>
						{column}
					</div>
				))}
			</div>
			{skeleton ? (
				<div className={styles.body}>
					{[null, null, null, null].map((_, rowIndex) => (
						<div key={rowIndex} className={styles.rowSkeleton}>
							{columns.map((_, index) => (
								<Skeleton key={index} className={styles.cellSkeleton} />
							))}
						</div>
					))}
				</div>
			) : (
				<div className={styles.body}>
					{data?.map((row, rowIndex) => (
						<div key={rowIndex} className={styles.row}>
							{row.map((cell, cellIndex) => (
								<div
									key={cellIndex}
									className={`${styles.cell} ${cellIndex === 0 ? styles.firstColumn : ''}`}>
									{cell}
								</div>
							))}
						</div>
					))}
				</div>
			)}
		</div>
	)
}
