import React, { useEffect } from 'react'
import styles from './Referrals.module.css'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { APBoard } from '../../APDS/APBoard/APBoard'
import useAuth from '../../Hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { useRefCode } from '../../Hooks/useRefCode'

export const ReferralPage: React.FC = () => {
	const { isLoggedIn } = useAuth()
	const { createUniqueRefCode, referrals, refCodeLoading, referralsLoading, getReferrals } =
		useRefCode()
	const navigate = useNavigate()

	const columns = ['Code', 'date generated', 'credit obtained']

	useEffect(() => {
		if (isLoggedIn === false) {
			navigate('/')
		} else if (isLoggedIn) {
			getReferrals()
		}
	}, [isLoggedIn])

	const handleGenerateCode = async () => {
		createUniqueRefCode()
	}

	return (
		<div className={styles.fullWidthBackground}>
			<div className={styles.container}>
				<a href="/" className={styles.back}>
					&#8592; Back
				</a>
				<h1 className={styles.title}>Referrals</h1>
				<h2 className={styles.subheading}>Welcome to our referral program</h2>
				<div className={styles.instructions}>
					<p>
						<strong>How it works:</strong>
					</p>
					<ol>
						<li>1. Generate a referral code</li>
						<li>2. Refer a friend by providing the referral code</li>
						<li>3. Your friend enters the referral code in the ‘access code’ box upon signup</li>
						<li>
							4. Every time your friend makes an in-store payment, we give you 25% of the payment in
							in-store credits
						</li>
					</ol>
				</div>
				<div className={styles.buttonContainer}>
					<APButtonPrimary
						className={styles.primaryButton}
						onClick={handleGenerateCode}
						isLoading={refCodeLoading}
						disabled={refCodeLoading}
						fitContent>
						Generate Referral code
					</APButtonPrimary>
				</div>
				<div className={styles.board}>
					<APBoard columns={columns} data={referrals} skeleton={referralsLoading} />
				</div>
			</div>
		</div>
	)
}
