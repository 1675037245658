import React, { FC } from 'react'
import styles from './ContactModal.module.css'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'

interface ContactModalProps {
	className?: string
}

export const ContactModal: FC<ContactModalProps> = ({ className }) => {
	const { modal, closeCurrentModal } = useModalControl()

	const handleClose = () => {
		closeCurrentModal()
	}

	return (
		modal === MODAL_TYPES.CONTACT && (
			<div className={styles.backdrop} onClick={handleClose}>
				<div
					className={`${styles.modal} ${className ? className : ''}`}
					onClick={(e) => e.stopPropagation()}>
					<h2>Contact Info:</h2>
					<p>If you require support or assistance please contact support@armoireprivee.com</p>
					<APButtonPrimary className={styles.closeButton} onClick={handleClose}>
						Return
					</APButtonPrimary>
				</div>
			</div>
		)
	)
}
