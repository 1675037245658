import React from 'react'
import styles from './APProduct.module.css'
import { Skeleton } from '@mui/material'
import { useProductInfo } from '../../Hooks/useProductInfo'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import useAuth from '../../Hooks/useAuth'

interface APProductProps {
	className?: string
	skeleton?: boolean
	image?: string
	brand?: string
	name?: string
	productUrl?: string
	originalPrice?: number
	salePrice?: number
	exclusive?: boolean
	source?: string
}

export const APProduct: React.FC<APProductProps> = ({
	skeleton,
	className,
	image,
	brand,
	name,
	productUrl,
	originalPrice,
	salePrice,
	exclusive,
	source
}) => {
	const { switchModal } = useModalControl()
	const { getProductInformation } = useProductInfo()
	const { isLoggedIn } = useAuth()

	const handleAddToCart = () => {
		if (!isLoggedIn) {
			switchModal(MODAL_TYPES.LOGIN)
		} else {
			if (productUrl) {
				getProductInformation({
					id: '',
					brand: brand || '',
					imageUrl: image || '',
					productUrl,
					name: name || '',
					priceAmount: salePrice || 0,
					regularPrice: originalPrice || 0,
					source: source || ''
				})
			}
		}
	}

	return skeleton ? (
		<div className={`${styles.card} ${className || ''}`}>
			<Skeleton variant="rectangular" className={styles.imageSkeleton} />
			<Skeleton className={styles.brandSkeleton} />
			<Skeleton className={styles.productSkeleton} />
			<Skeleton className={styles.descriptionSkeleton} />
			<Skeleton className={styles.descriptionSkeleton} />
			<Skeleton className={styles.priceSkeleton} />
			<Skeleton className={styles.addToCartSkeleton} />
		</div>
	) : (
		<div className={`${styles.card} ${className || ''}`}>
			<div className={styles.imageContainer}>
				<img src={image} alt={name} className={styles.image} />
				{exclusive && <span className={styles.exclusive}>exclusive</span>}
			</div>
			<div className={styles.detailsWrapper}>
				<div className={styles.details}>
					<h3 className={styles.brand}>{brand}</h3>
					<p className={styles.name}>{name}</p>
					<div className={styles.priceContainer}>
						<span className={styles.originalPrice}>${originalPrice}</span>
						<span className={styles.salePrice}>Now ${salePrice}</span>
					</div>
				</div>
				<a onClick={handleAddToCart} className={styles.addToCart}>
					Add to Cart +
				</a>
			</div>
		</div>
	)
}
