import React, { ButtonHTMLAttributes } from 'react';
import styles from './APButtonPrimary.module.css';
import { CircularProgress } from '@mui/material';

interface APButtonPrimaryProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    isLoading?: boolean;
    fitContent?: boolean; // New prop to control button width
}

export const APButtonPrimary: React.FC<APButtonPrimaryProps> = ({
    className,
    children,
    isLoading,
    fitContent = false, // Default is false
    ...props
}) => {
    return (
        <button
            className={`${styles.button} ${fitContent ? styles.buttonFitContent : ''} ${className || ''}`}
            {...props}
        >
            {isLoading ? <CircularProgress size={16} color="inherit" /> : children}
        </button>
    );
};
