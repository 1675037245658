import { useDispatch, useSelector } from "react-redux";
import { Product, Size } from "../Types/Product";
import { useApiClient } from "./useApiClient"
import { APState } from "../Types";
import { useModalControl } from "./useModalControl";
import { MODAL_TYPES } from "../Types/Modal";
import { setProductInfo, setProductInfoLoading } from "../Redux/productInfoSlice";
import { useCustomAlert } from "./useCustomAlert";

export const useProductInfo = () => {

    const dispatch = useDispatch()
    const { getSizes } = useApiClient();
    const { sendErrorAlert } = useCustomAlert()
    const { openNewModal, switchModal, closeCurrentModal } = useModalControl()
    const productInfo = useSelector((state: APState) => state.productInfo.productInfo)

    const getProductInformation = async (product: Product) => {
        dispatch(setProductInfoLoading(true))
        openNewModal(MODAL_TYPES.FETCHING_SIZES)
        console.log("PRODUCT", product)
        getSizes(product.productUrl, product.source).then(async (response) => {
            try {
                const sizes = await response.json();

                if (sizes.length === 0) {
                    dispatch(setProductInfoLoading(false))
                    switchModal(MODAL_TYPES.NOSIZES)
                    return
                }

                const filteredSizes = sizes?.filter((size: Size) => size.inStock)
                dispatch(setProductInfo({ ...product, sizes: filteredSizes }))
                dispatch(setProductInfoLoading(false))
                switchModal(MODAL_TYPES.SIZE_SELECTION)
            } catch (error) {
                sendErrorAlert('An unknown error occured')
                dispatch(setProductInfoLoading(false))
                closeCurrentModal()
            }

        }).catch(() => {
            sendErrorAlert('An unknown error occured')
            dispatch(setProductInfoLoading(false))
            closeCurrentModal()
        })
    }
    return {
        productInfo,
        getProductInformation
    }

}