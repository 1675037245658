import { useState } from "react"
import { useApiClient } from "./useApiClient"
import { useModalControl } from "./useModalControl"
import { MODAL_TYPES } from "../Types/Modal"
import { useDispatch, useSelector } from "react-redux"
import { setRefCode } from "../Redux/refCodeSlice"
import { APState } from "../Types"
//import { send } from "process"
import { useCustomAlert } from "./useCustomAlert"

export const useRefCode = () => {

    const { openNewModal } = useModalControl()
    const dispatch = useDispatch()

    const refCode = useSelector((state: APState) => state.refCode.refCode)
    const [referralsLoading, setReferralsLoading] = useState<boolean>(false)
    const [refCodeLoading, setRefCodeLoading] = useState<boolean>(false)
    const [confirmRefCodeLoading, setConfirmRefCodeLoading] = useState<boolean>(false)
    const [referrals, setReferrals] = useState([])

    const { sendSuccessAlert } = useCustomAlert()
    const { getUserReferrals, generateRefCode, addUserReferral } = useApiClient()

    const createUniqueRefCode = async () => {
        setRefCodeLoading(true)
        await generateRefCode().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    dispatch(setRefCode(data.code))
                    openNewModal(MODAL_TYPES.REFCODE)
                })
            }
        })
        setRefCodeLoading(false)
    }

    console.log("arrOfArrs", referrals)
    const getReferrals = async () => {
        setReferralsLoading(true)
        await getUserReferrals().then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const arrOfArrs = data?.referrals?.map((referral: { createdAt: string | number | Date; code: string, creditObtained: number }) => {
                        const date = new Date(referral.createdAt)
                        const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
                        return [referral.code, formattedDate, `${referral.creditObtained}$`]
                    })

                    setReferrals(arrOfArrs)
                })
            }
        })
        setReferralsLoading(false)
    }


    const confirmRefCode = async (quantity: number, code: string) => {
        setConfirmRefCodeLoading(true)
        await addUserReferral(quantity, code).then((response) => {
            if (response.ok) {
                sendSuccessAlert('Referral code added successfully')
            }
        })
        setConfirmRefCodeLoading(false)
        window.location.reload()
    }



    return { confirmRefCodeLoading, referralsLoading, refCodeLoading, refCode, referrals, getReferrals, createUniqueRefCode, confirmRefCode }
}