import { useState } from "react"
import { useApiClient } from "./useApiClient"
import { Product } from "../Types/Product"

export const useOrders = () => {

    const [orders, setOrders] = useState([])
    const [ordersLoading, setOrdersLoading] = useState(false)
    const { getOrders } = useApiClient()


    const getUserOrders = async () => {

        setOrdersLoading(true)
        const orders = await getOrders()

        if (orders.ok) {
            orders.json().then((data) => {
                console.log("orders", data.orders)


                const adjustedOrders = data.orders.map((order: { id: string, createdAt: { _seconds: number }, totalAmount: number, cartData: { items: Product[] } }) => {
                    // format date as dd/mm/yyyy
                    const formattedDate = new Date(order.createdAt._seconds * 1000).getDate() + "/" + (new Date(order.createdAt._seconds * 1000).getMonth() + 1) + "/" + new Date(order.createdAt._seconds * 1000).getFullYear()
                    return {
                        orderId: order.id,
                        date: formattedDate,
                        amount: order.totalAmount,
                        products: order.cartData.items
                    }
                })

                setOrders(adjustedOrders)
            })
        }
        setOrdersLoading(false)
    }

    return { ordersLoading, orders, getUserOrders }
}