import { useDispatch, useSelector } from "react-redux"
import { APState } from "../Types"
import { OpenModalPayload, OpenModalType } from "../Types/Modal";
import { closeModal, openModal } from "../Redux/modalSlice";

export const useModalControl = () => {

    const modal = useSelector((state: APState) => state.modal.openModal);
    const dispatch = useDispatch();


    const switchModal = (modalType: OpenModalType, payload?: OpenModalPayload) => {
        dispatch(closeModal());
        dispatch(openModal({ openModal: modalType, payload: payload || null }));
    }

    const closeCurrentModal = () => {
        dispatch(closeModal());
    }

    const openNewModal = (modalType: OpenModalType, payload?: OpenModalPayload) => {
        dispatch(openModal({ openModal: modalType, payload: payload || null }));
    }

    return { modal, switchModal, closeCurrentModal, openNewModal }

}