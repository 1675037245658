import { createSlice } from "@reduxjs/toolkit";

const accountSlice = createSlice({
    name: 'account',
    initialState: {
        uid: null,
        token: null,
        email: null,
        isLoggedIn: null as boolean | null,
    },
    reducers: {
        login(state) {
            state.isLoggedIn = true;
        },
        logout(state) {
            state.isLoggedIn = false;
        },
        setUid: (state, action) => {
            state.uid = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
    },
});


export const { setUid, setToken, setEmail, login, logout } = accountSlice.actions;
export default accountSlice.reducer;
