import React from 'react'
import styles from './ItemsAddedModal.module.css'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { APButtonSecondary } from '../../APDS/APButtonSecondary/APButtonSecondary'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import { useCart } from '../../Hooks/useCart'

export const ItemAddedModal: React.FC = () => {
	const { modal, closeCurrentModal } = useModalControl()
	const { redirectToCart } = useCart()

	const handleClose = () => {
		closeCurrentModal()
	}

	const handleViewCart = () => {
		redirectToCart()
	}
	return (
		modal === MODAL_TYPES.ITEMS_ADDED && (
			<div className={styles.backdrop} onClick={handleClose}>
				<div className={styles.modal} onClick={(e) => e.stopPropagation()}>
					<h2 className={styles.title}>Items added successfully</h2>
					<div className={styles.buttonsContainer}>
						<APButtonPrimary className={styles.primaryButton} onClick={handleViewCart}>
							View Cart
						</APButtonPrimary>
						<APButtonSecondary className={styles.secondaryButton} onClick={handleClose}>
							Continue shopping
						</APButtonSecondary>
					</div>
				</div>
			</div>
		)
	)
}
