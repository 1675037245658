import React from 'react'
import styles from './FetchingSizesModal.module.css'
import { APLoader } from '../../APDS/APLoader/APLoader'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'

export const FetchingSizesModal: React.FC = () => {
	const { modal } = useModalControl()

	return (
		modal === MODAL_TYPES.FETCHING_SIZES && (
			<div className={styles.backdrop}>
				<div className={styles.modal} onClick={(e) => e.stopPropagation()}>
					<h2 className={styles.title}>Fetching available sizes</h2>
					<div className={styles.loaderContainer}>
						<APLoader />
					</div>
				</div>
			</div>
		)
	)
}
