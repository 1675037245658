import React from 'react'
import styles from './ConfirmUnsubModal.module.css'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { APButtonSecondary } from '../../APDS/APButtonSecondary/APButtonSecondary'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import { useAccount } from '../../Hooks/useAccount'

export const ConfirmUnsubModal: React.FC = () => {
	const { modal, closeCurrentModal } = useModalControl()
	const { cancelUserSubscription } = useAccount()
	const [unsubLoading, setUnsubLoading] = React.useState(false)

	const handleKeep = () => {
		closeCurrentModal()
	}

	const handleCancel = async () => {
		setUnsubLoading(true)
		await cancelUserSubscription()
		setUnsubLoading(false)
		window.location.reload()
	}

	return (
		modal === MODAL_TYPES.CANCEL_SUBSCRIPTION && (
			<div className={styles.backdrop} onClick={handleKeep}>
				<div className={styles.modal} onClick={(e) => e.stopPropagation()}>
					<h2 className={styles.title}>
						Are you sure you want to Unsubscribe from Armoire Privée?
					</h2>
					<p className={styles.description}>You will miss out on premium prices.</p>
					<div className={styles.buttonsContainer}>
						<APButtonSecondary className={styles.secondaryButton} onClick={handleKeep}>
							Keep Subscription
						</APButtonSecondary>
						<APButtonPrimary
							className={styles.primaryButton}
							onClick={handleCancel}
							isLoading={unsubLoading}
							disabled={unsubLoading}>
							Cancel Subscription
						</APButtonPrimary>
					</div>
				</div>
			</div>
		)
	)
}
