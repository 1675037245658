import React, { ButtonHTMLAttributes } from 'react'
import styles from './APButtonSecondary.module.css'
import { CircularProgress } from '@mui/material'

interface APButtonSecondaryProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string
	isLoading?: boolean
	fitContent?: boolean;
}

export const APButtonSecondary: React.FC<APButtonSecondaryProps> = ({
	className,
	children,
	isLoading,
	fitContent = false,
	...props
}) => {
	return (
		<button
            className={`${styles.button} ${fitContent ? styles.buttonFitContent : ''} ${className || ''}`}
            {...props}
        >
            {isLoading ? <CircularProgress size={16} color="inherit" /> : children}
        </button>
	)
}
