import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import CustomAlert from '../Components/CustomAlert/CustomAlert'
import { Home } from '../Pages/Home'
import ModalControl from '../Components/ModalControl/ModalControl'
import useAuth from '../Hooks/useAuth'
import { ReferralPage } from '../Pages/Referrals'
import { AccountPage } from '../Pages/Account/Account.stories'
import Orders from '../Pages/Orders/Orders'
function App() {
	const { persistLogin } = useAuth()

	useEffect(() => {
		const unsubscribe = persistLogin()
		return () => unsubscribe()
	}, [])

	return (
		<>
			<CustomAlert />
			<ModalControl />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/referrals" element={<ReferralPage />} />
				<Route path="/account" element={<AccountPage />} />
				<Route path="/orders" element={<Orders orders={[]} totalOrders={0} />} />
			</Routes>
		</>
	)
}

export default App
