import React, { useEffect } from 'react'
import { APBoard } from '../../APDS'
import { APButtonPrimary } from '../../APDS/APButtonPrimary'
import { APButtonSecondary } from '../../APDS/APButtonSecondary'
import styles from './Account.module.css'
import useAuth from '../../Hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { useAccount } from '../../Hooks/useAccount'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'

export const Account = () => {
	const { isLoggedIn } = useAuth()
	const {
		subscriptionId,
		inStoreCredit,
		pastSubscriptions,
		accountLoading,
		nextBillingDate,
		getAccountInfo
	} = useAccount()
	const navigate = useNavigate()
	const { switchModal } = useModalControl()

	const columns = ['ID', 'Date', 'Amount', 'Method']
	useEffect(() => {
		if (isLoggedIn === false) {
			navigate('/')
		} else if (isLoggedIn) {
			getAccountInfo()
		}
	}, [isLoggedIn])

	const handleCancelSubscription = () => {
		switchModal(MODAL_TYPES.CANCEL_SUBSCRIPTION)
	}

	const handleDeleteAccount = () => {
		switchModal(MODAL_TYPES.DELETE_ACCOUNT)
	}

	return (
		<div className={styles.accountContainer}>
			<a href="/" className={styles.back}>
				&#8592; Back
			</a>
			<h1 className={styles.pageHeader}>Account</h1>
			<p className={styles.billingCycle}>Next Billing cycle: {nextBillingDate}</p>
			<p className={styles.billingCycle}> In store credits: {inStoreCredit}$</p>

			<p className={styles.subHeader}>subscription payments</p>

			<div className={styles.boardWrapper}>
				<APBoard
					columns={columns}
					skeleton={accountLoading}
					data={pastSubscriptions}
					className={styles.accountBoard}
				/>
			</div>

			<div className={styles.buttonWrapper}>
				{subscriptionId && (
					<APButtonPrimary onClick={handleCancelSubscription}>Cancel Subscription</APButtonPrimary>
				)}
				<APButtonSecondary onClick={handleDeleteAccount}>Delete Account</APButtonSecondary>
			</div>
		</div>
	)
}

export default Account
