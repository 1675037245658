import { useState } from "react";
import { useApiClient } from "./useApiClient"
import { Brand } from "../Types/Brand";
import { Gender } from "../Types/Gender";
import { Product } from "../Types/Product";
import { Category } from "../Types/Category";


export const useProducts = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [brands, setBrands] = useState<Brand[]>();
    const [unfilteredProducts, setUnfilteredProducts] = useState<Product[]>();
    const [products, setProducts] = useState<Product[]>();
    const [categoryFilters, setCategoryFilters] = useState<Category[]>([]);
    const [gender, setGender] = useState<Gender>("men")
    const [brand, setBrand] = useState<string>('');
    const [genders] = useState<Gender[]>(["men", "women"])
    const [selectedCategory, setSelectedCategory] = useState<number>(-1)
    const [priceRange, setPriceRange] = useState({ min: 0, max: 1000, currentValue: 1000 })

    const { getFilters, getProducts } = useApiClient();

    const getHighestPrice = (products: Product[]) => {
        return Math.max(...products.map((product: Product) => product.priceAmount))
    }

    const adjustSliderParams = (products: Product[]) => {
        setPriceRange((prev) => ({ ...prev, currentValue: getHighestPrice(products), max: getHighestPrice(products) }))
    }

    const getInitialBrandsAndProducts = async () => {
        setIsLoading(true)

        const { brands } = await getFilters(gender)
        const sortedBrands: Brand[] = brands.sort((a: Brand, b: Brand) => a.name.localeCompare(b.name)); // alphanumeric sort

        const mainBrand = sortedBrands.find((b: Brand) => b.seoKeyword === "ap-specials") || sortedBrands[0]

        const { products, categories } = await getProducts(mainBrand.seoKeyword, "men")

        setCategoryFilters(categories)
        setBrand(mainBrand.seoKeyword)
        setBrands(sortedBrands)
        setProducts(products);
        setUnfilteredProducts(products)
        setIsLoading(false)
        setSelectedCategory(-1)
        adjustSliderParams(products)
    }

    const changeBrand = async (seoKeyWord: string) => {
        setIsLoading(true)
        setBrand(seoKeyWord)

        const { products, categories } = await getProducts(seoKeyWord, gender);

        setProducts(products)
        setUnfilteredProducts(products)
        setIsLoading(false)
        setCategoryFilters(categories)
        setSelectedCategory(-1)
        adjustSliderParams(products)
    }

    const changeGender = async (gender: Gender) => {
        setIsLoading(true)
        setGender(gender)

        const { brands } = await getFilters(gender)

        // if no brands found, go back to the original filter
        if (!brands) {
            setIsLoading(false)
            setGender(gender === "men" ? "women" : "men")
            return;
        }

        const sortedBrands: Brand[] = brands.sort((a: Brand, b: Brand) => a.name.localeCompare(b.name)); // alphanumeric sort

        // find current brand in brands 
        const current = sortedBrands.find((b: Brand) => b.seoKeyword === brand)

        if (current) {
            const { products, categories } = await getProducts(brand, gender)
            setCategoryFilters(categories)
            setProducts(products)
            setBrands(sortedBrands)
            setUnfilteredProducts(products)
            setIsLoading(false)
            adjustSliderParams(products)
        } else {
            const { products, categories } = await getProducts(sortedBrands[0].seoKeyword, gender)
            setCategoryFilters(categories)
            setBrands(sortedBrands)
            setBrand(sortedBrands[0].seoKeyword)
            setProducts(products)
            setUnfilteredProducts(products)
            setIsLoading(false)
            setSelectedCategory(-1)
            adjustSliderParams(products)
        }

    }

    const changeCategory = async (category: number) => {
        const filteredProducts = category !== -1 ? unfilteredProducts?.filter((product: Product) => product.categories?.includes(category.toString())) : unfilteredProducts
        setProducts(filteredProducts)
        selectedCategory === category ? setSelectedCategory(-1) : setSelectedCategory(category)
    }

    const changePrice = async (maxPrice: number) => {
        const filteredProducts = unfilteredProducts?.filter((product: Product) => product.priceAmount <= maxPrice)
        setProducts(filteredProducts)
        setPriceRange({ min: priceRange.min, max: priceRange.max, currentValue: maxPrice })

    }


    return {
        priceRange,
        isLoading,
        brand,
        gender,
        brands,
        products,
        genders,
        categoryFilters,
        selectedCategory,
        getInitialBrandsAndProducts,
        changeBrand,
        changeGender,
        changeCategory,
        changePrice
    }
}