import React, { InputHTMLAttributes } from 'react';
import styles from './APTextbox.module.css';

interface APTextboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const APTextbox: React.FC<APTextboxProps> = ({
  className,
  ...props
}) => {
  return (
    <input
      className={`${styles.textbox} ${className || ''}`}
      {...props}
    />
  );
};
