import React, { useState } from 'react'
import styles from './APBoardVariant.module.css'
import { Skeleton } from '@mui/material'

interface Product {
	name: string
	salePrice: string
}

interface Order {
	orderId: string
	date: string
	amount: string
	payment: string // Added payment field
	products: Product[]
}

interface APBoardVariantProps {
	columns: string[]
	orders: Order[]
	skeleton?: boolean
}

const APBoardVariant: React.FC<APBoardVariantProps> = ({ orders, columns, skeleton }) => {
	const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

	const handleAccordionToggle = (index: number) => {
		setExpandedIndex(index === expandedIndex ? null : index)
	}

	return (
		<div className={styles.board}>
			<div className={styles.header}>
				<div className={styles.columnHeader}></div> {/* Empty for arrow */}
				<div className={styles.columnHeader}>ID</div>
				<div className={styles.columnHeader}>Date</div>
				<div className={styles.columnHeader}>Amount</div>
				<div className={styles.columnHeader}>Method</div>
				<div className={styles.columnHeader}>Track Order</div> {/* Empty for button */}
			</div>
			<>
				{skeleton ? (
					<div className={styles.body}>
						{[null, null, null, null].map((_, rowIndex) => (
							<div key={rowIndex} className={styles.rowSkeleton}>
								{columns.map((_, index) => (
									<Skeleton key={index} className={styles.cellSkeleton} />
								))}
							</div>
						))}
					</div>
				) : (
					<>
						{orders.map((order, index) => (
							<div key={index}>
								<div className={styles.orderRow} onClick={() => handleAccordionToggle(index)}>
									<span className={styles.arrow}>
										<svg
											className={`${styles.chevron} ${expandedIndex === index ? styles.chevronDown : ''}`}
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9 6l6 6-6 6"
												stroke="currentColor"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
									<span className={styles.orderId}>{order.orderId}</span>
									<span className={styles.orderDate}>{order.date}</span>
									<span className={`${styles.orderAmount} ${styles.price}`}>
										<strong>${order.amount}</strong>
									</span>
									<span className={styles.orderPayment}>Debit/Credit</span>
									<button className={styles.trackButton}>
										Track
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 512 512"
											fill="currentColor">
											<path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112v320c0 44.2 35.8 80 80 80h320c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v112c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16h112c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
										</svg>
									</button>
								</div>
								{expandedIndex === index && (
									<div className={styles.productList}>
										{order.products.map((product, idx) => (
											<div key={idx} className={styles.productRow}>
												<span>{product.name}</span>
												<span></span>
												<span className={styles.productCell}>${product.salePrice}</span>
											</div>
										))}
									</div>
								)}
							</div>
						))}
					</>
				)}
			</>
		</div>
	)
}

export default APBoardVariant
