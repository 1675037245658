import { createSlice } from '@reduxjs/toolkit';

const productInfoSlice = createSlice({
    name: 'productInfo',
    initialState: {
        productInfo: null,
        isLoading: false,
    },
    reducers: {
        setProductInfo: (state, action) => {
            state.productInfo = action.payload;
        },
        setProductInfoLoading: (state, action) => {
            state.isLoading = action.payload
        }
    },
});

export const { setProductInfo, setProductInfoLoading } = productInfoSlice.actions;
export default productInfoSlice.reducer;