import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        openModal: null,
        payload: null,
    },
    reducers: {
        openModal: (state, action) => {
            state.openModal = action.payload.openModal;
            state.payload = action.payload.payload;
        },
        closeModal: (state) => {
            state.openModal = null;
            state.payload = null;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;