import React from 'react'
import styles from './ConfirmDeleteModal.module.css'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { APButtonSecondary } from '../../APDS/APButtonSecondary/APButtonSecondary'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import { useAccount } from '../../Hooks/useAccount'

export const ConfirmDeleteModal: React.FC = () => {
	const { modal, closeCurrentModal } = useModalControl()
	const { deleteUserAccount } = useAccount()
	const [deletingLoading, setDeletingLoading] = React.useState(false)

	const handleKeep = () => {
		closeCurrentModal()
	}

	const handleDelete = async () => {
		setDeletingLoading(true)
		await deleteUserAccount()
		setDeletingLoading(false)
		window.location.reload()
	}

	return (
		modal === MODAL_TYPES.DELETE_ACCOUNT && (
			<div className={styles.backdrop} onClick={handleKeep}>
				<div className={styles.modal} onClick={(e) => e.stopPropagation()}>
					<h2 className={styles.title}>Are you sure you want to delete this account?</h2>
					<p className={styles.description}>This action cannot be reverted.</p>
					<div className={styles.buttonsContainer}>
						<APButtonSecondary className={styles.secondaryButton} onClick={handleKeep}>
							Keep Account
						</APButtonSecondary>
						<APButtonPrimary
							className={styles.primaryButton}
							onClick={handleDelete}
							isLoading={deletingLoading}
							disabled={deletingLoading}>
							Delete Account
						</APButtonPrimary>
					</div>
				</div>
			</div>
		)
	)
}
