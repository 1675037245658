import { useState } from "react"
import { useApiClient } from "./useApiClient"
import { useCustomAlert } from "./useCustomAlert"

export const useAccount = () => {
    const [accountLoading, setAccountLoading] = useState<boolean>(false)
    const [inStoreCredit, setInStoreCredit] = useState<number | null>(null)
    const [nextBillingDate, setNextBillingDate] = useState<string | null>(null)
    const [pastSubscriptions, setPastSubscriptions] = useState<string[][]>([])
    const [subscriptionId, setSubscriptionId] = useState<string | null>(null)


    const { getAccount, cancelSubscription, deleteAccount } = useApiClient()
    const { sendSuccessAlert } = useCustomAlert()


    const getAccountInfo = async () => {
        setAccountLoading(true)

        await getAccount().then(async (response) => {
            if (response.ok) {
                const data = await response.json()

                const timestamp = data?.nextTopOff?._seconds * 1000;
                const date = new Date(timestamp);

                const nextDay = String(date.getDate()).padStart(2, '0');
                const nextMonth = String(date.getMonth() + 1).padStart(2, '0');
                const nextYear = date.getFullYear();

                const formattedDate = `${nextMonth}/${nextDay}/${nextYear} (mm/dd/yyyy)`;
                setNextBillingDate(formattedDate)
                setInStoreCredit(data?.inStoreCredits)


                const subPayments = data?.subscriptionBills?.map((bill: { date: { _seconds: number }, billId: string, amount: number, method: string }) => {
                    const timestamp = bill?.date?._seconds * 1000;
                    const date = new Date(timestamp);

                    const nextDay = String(date.getDate()).padStart(2, '0');
                    const nextMonth = String(date.getMonth() + 1).padStart(2, '0');
                    const nextYear = date.getFullYear();

                    const formattedDate = `${nextMonth}/${nextDay}/${nextYear}`;
                    const billId = bill.billId
                    const dollarAmount = `$${bill.amount.toFixed(2)}`
                    const method = bill.method

                    return [billId, formattedDate, dollarAmount, method]

                })

                setSubscriptionId(data?.subscriptionId)
                setPastSubscriptions(subPayments)

            }
        })
        setAccountLoading(false)
    }

    const cancelUserSubscription = async () => {
        await cancelSubscription().then(async (response) => {
            if (response.ok) {
                getAccountInfo()
            }
        }).then(() => {
            sendSuccessAlert("Subscription cancelled successfully")
        }).catch(() => {
            sendSuccessAlert("Error cancelling subscription")
        })
    }

    const checkTopOff = async (token: string) => {
        return await getAccount(token).then(async (response) => {
            if (response.ok) {
                const data = await response.json()
                const isToppedOff = data?.isToppedOff
                return isToppedOff
            }
            return false
        }).finally(() => { return false })
    }

    const deleteUserAccount = async () => {
        await deleteAccount().then(async (response) => {
            if (response.ok) {
                sendSuccessAlert("Account deleted successfully")
            }
        }).catch(() => {
            sendSuccessAlert("Error deleting account")
        })
    }


    return {
        subscriptionId,
        inStoreCredit,
        pastSubscriptions,
        accountLoading,
        nextBillingDate,
        checkTopOff,
        getAccountInfo,
        deleteUserAccount,
        cancelUserSubscription
    }
}