import { useState } from "react"
import { APState, CartItem } from "../Types"
import { useApiClient } from "./useApiClient"
import { useModalControl } from "./useModalControl"
import { MODAL_TYPES } from "../Types/Modal"
import { useCustomAlert } from "./useCustomAlert"
import { useDispatch, useSelector } from "react-redux"
import { setCart, setCartLoading, setInStoreCredit } from "../Redux/cartSlice"

export const useCart = () => {
    const [addToCartLoading, setAddToCartLoading] = useState<boolean>(false)

    const { sendErrorAlert, sendSuccessAlert, sendInfoAlert } = useCustomAlert()
    const { switchModal, closeCurrentModal } = useModalControl()
    const { addToCart, getCart, removeCartItem } = useApiClient()
    const cartLoading = useSelector((state: APState) => state.cart.cartLoading)
    const cart = useSelector((state: APState) => state.cart.cart)
    const inStoreCredit = useSelector((state: APState) => state.cart.inStoreCredit)

    const dispatch = useDispatch()


    const caclulateTotalOriginalPrice = (items: CartItem[]) => {
        return items.reduce((acc, item) => {
            return acc + item.regularPrice * item.quantity
        }, 0)
    }

    const calculateTotalSalePrice = (items: CartItem[]) => {
        return items.reduce((acc, item) => {
            return acc + item.salePrice * item.quantity
        }, 0)
    }

    const calculateTotalSavings = (items: CartItem[], inStoreCredit: number) => {
        const totalOriginalPrice = caclulateTotalOriginalPrice(items)
        const totalSalePrice = calculateTotalSalePrice(items)
        return totalOriginalPrice - totalSalePrice + Math.min(totalSalePrice, inStoreCredit)
    }

    const addItemToCart = async (item: CartItem) => {
        setAddToCartLoading(true)
        addToCart(item).then(async (response) => {
            if (response.ok) {
                switchModal(MODAL_TYPES.ITEMS_ADDED)
            } else {
                const text = await response.text()
                sendErrorAlert(text)
                closeCurrentModal()
            }
        }).catch(() => {
            sendErrorAlert('An unknown error occured')
            closeCurrentModal()
        }).finally(() => {
            setAddToCartLoading(false)
        })
    }



    const redirectToCart = async () => {
        dispatch(setCartLoading(true))

        await getCart().then(async (response) => {
            if (response.ok) {
                const cart = await response.json()
                dispatch(setCart(cart.cart.items))
                dispatch(setInStoreCredit(cart.inStoreCredits))
                switchModal(MODAL_TYPES.CART)
            } else if (response.status === 403) {
                switchModal(MODAL_TYPES.SUBSCRIPTION)
                sendInfoAlert('You need to be subscribed to access the cart')
            } else {
                sendErrorAlert('An unknown error occured')
            }
        }).catch(() => {
            sendErrorAlert('An unknown error occured')
        })

        dispatch(setCartLoading(false))
    }

    const removeFromCart = async (productUrl: string, quantity: number) => {
        await removeCartItem(productUrl, quantity).then((response) => {
            if (response.ok) {
                sendSuccessAlert("Item removed from cart")
                redirectToCart()
            } else {
                sendErrorAlert("Unexpected error occured")
            }
        }).catch((err) => {
            sendErrorAlert("Unexpected error occured")
        })
    }


    return { cart, inStoreCredit, cartLoading, addToCartLoading, removeFromCart, addItemToCart, redirectToCart, calculateTotalSalePrice, caclulateTotalOriginalPrice, calculateTotalSavings }
}