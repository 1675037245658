import React, { FC, useState } from 'react'
import styles from './SignUpModal.module.css'
import { APTextbox } from '../../APDS/APTextbox/APTextbox' // Adjust paths as needed
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { APButtonSecondary } from '../../APDS/APButtonSecondary/APButtonSecondary'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import useAuth from '../../Hooks/useAuth'
import { useCustomAlert } from '../../Hooks/useCustomAlert'

interface SignUpModalProps {
	className?: string
}

export const SignUpModal: FC<SignUpModalProps> = ({ className }) => {
	const { modal, closeCurrentModal, switchModal } = useModalControl()
	const { sendErrorAlert } = useCustomAlert()
	const { signUp } = useAuth()

	const [isLoading, setIsLoading] = useState(false)
	const [accessCode, setAccessCode] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const handleAccessCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAccessCode(e.target.value)
	}

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value)
	}

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(e.target.value)
	}

	const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setConfirmPassword(e.target.value)
	}

	const handleSignUp = async () => {
		if (password === confirmPassword) {
			setIsLoading(true)
			await signUp(accessCode, email, password)
			setIsLoading(false)
		} else {
			sendErrorAlert('Passwords do not match. Please make sure they are the same and try again.')
		}
	}

	const handleClose = () => {
		closeCurrentModal()
	}

	const goToSignIn = () => {
		switchModal(MODAL_TYPES.LOGIN)
	}
	return (
		modal === MODAL_TYPES.REGISTER && (
			<div className={styles.backdrop} onClick={handleClose}>
				<div
					className={`${styles.modal} ${className ? className : ''}`}
					onClick={(e) => e.stopPropagation()}>
					<h2>Sign up</h2>
					<APTextbox placeholder="Access Code" onChange={handleAccessCodeChange} />
					<APTextbox placeholder="Email" onChange={handleEmailChange} />
					<APTextbox placeholder="Password" type="password" onChange={handlePasswordChange} />
					<APTextbox
						placeholder="Confirm Password"
						type="password"
						onChange={handleConfirmPasswordChange}
					/>
					<APButtonPrimary
						isLoading={isLoading}
						disabled={isLoading}
						className={styles.primaryButton}
						onClick={handleSignUp}>
						Sign up
					</APButtonPrimary>
					<APButtonSecondary className={styles.secondaryButton} onClick={goToSignIn}>
						Already have an account
					</APButtonSecondary>
				</div>
			</div>
		)
	)
}
