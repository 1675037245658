import React, { SelectHTMLAttributes, useState } from 'react'
import styles from './APSelect.module.css'

interface APSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
	className?: string
}

export const APSelect: React.FC<APSelectProps> = ({ className, children, ...props }) => {
	const [isOpen, setIsOpen] = useState(false)

	const handleFocus = () => setIsOpen(true)
	const handleBlur = () => setIsOpen(false)

	return (
		<div className={`${styles.selectContainer} ${className || ''}`}>
			<select
				className={`${styles.select} ${className || ''}`}
				onFocus={handleFocus}
				onBlur={handleBlur}
				{...props}>
				{children}
			</select>
			<span className={styles.arrow}>
				{isOpen ? (
					<svg
						width="12"
						height="12"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M7 14l5-5 5 5"
							stroke="#808080"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				) : (
					<svg
						width="12"
						height="12"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M7 10l5 5 5-5"
							stroke="#808080"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				)}
			</span>
		</div>
	)
}
