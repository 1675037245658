import React, { useState } from 'react'
import styles from './SubscriptionModal.module.css'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import { useStripe } from '../../Hooks/useStripe'

type BillingCycle = 'Monthly' | 'Quarterly' | 'Annually'

export const SubscriptionModal: React.FC = () => {
	const { modal, closeCurrentModal } = useModalControl()
	const { checkoutLoading, redirectToSubscriptionCheckout } = useStripe()
	const [billingCycle, setBillingCycle] = useState<BillingCycle>('Monthly')

	const priceMap: Record<BillingCycle, string> = {
		Monthly: '30$',
		Quarterly: '25$',
		Annually: '20$'
	}

	const handleModalClose = () => {
		closeCurrentModal()
	}

	const handleSubscription = async () => {
		await redirectToSubscriptionCheckout(billingCycle.toUpperCase())
	}

	return (
		modal === MODAL_TYPES.SUBSCRIPTION && (
			<div className={styles.backdrop} onClick={handleModalClose}>
				<div className={styles.modal} onClick={(e) => e.stopPropagation()}>
					<h2 className={styles.title}>Start your subscription</h2>
					<div className={styles.price}>{priceMap[billingCycle]}/Month</div>
					<h3 className={styles.subtitle}>Choose billing cycle</h3>
					<div className={styles.billingCycle}>
						{(['Monthly', 'Quarterly', 'Annually'] as BillingCycle[]).map((cycle) => (
							<span
								key={cycle}
								className={`${styles.cycleOption} ${
									billingCycle === cycle ? styles.activeCycle : ''
								}`}
								onClick={() => setBillingCycle(cycle)}>
								{cycle}
							</span>
						))}
					</div>
					<p className={styles.description}>
						Discover Armoire Privée, your key to exclusive designer fashion. Unlock member-only
						access to curated collections at insider prices. Enjoy substantial discounts and shop
						new arrivals before anyone else. Elevate your wardrobe with Armoire Privée – where
						luxury meets accessibility.
					</p>
					<APButtonPrimary
						className={styles.primaryButton}
						onClick={handleSubscription}
						disabled={checkoutLoading}
						isLoading={checkoutLoading}>
						Go to subscription
					</APButtonPrimary>
				</div>
			</div>
		)
	)
}
