import { useDispatch } from "react-redux"
import { sendMessage } from "../Redux/alertSlice";

export const useCustomAlert = () => {
    const dispatch = useDispatch();

    const sendErrorAlert = (alertMessage: string) => {
        dispatch(sendMessage({ open: true, message: alertMessage, severity: 'error' }))
    }

    const sendSuccessAlert = (alertMessage: string) => {
        dispatch(sendMessage({ open: true, message: alertMessage, severity: 'success' }))
    }

    const sendWarningAlert = (alertMessage: string) => {
        dispatch(sendMessage({ open: true, message: alertMessage, severity: 'warning' }))
    }

    const sendInfoAlert = (alertMessage: string) => {
        dispatch(sendMessage({ open: true, message: alertMessage, severity: 'info' }))
    }

    return {
        sendErrorAlert,
        sendSuccessAlert,
        sendWarningAlert,
        sendInfoAlert
    }
}