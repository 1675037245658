import React from 'react'
import styles from './ShippingInfoModal.module.css'
import { APTextbox } from '../../APDS/APTextbox/APTextbox'
import { APButtonPrimary } from '../../APDS/APButtonPrimary/APButtonPrimary'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import { useStripe } from '../../Hooks/useStripe'
import { useCart } from '../../Hooks/useCart'
import { useApiClient } from '../../Hooks/useApiClient'
import { useCustomAlert } from '../../Hooks/useCustomAlert'

export const ShippingInfoModal: React.FC = () => {
	const { cart, inStoreCredit } = useCart()
	const { modal, closeCurrentModal } = useModalControl()
	const { redirectToProductCheckout } = useStripe()
	const { addShippingInfo } = useApiClient()
	const { sendErrorAlert } = useCustomAlert()

	const [firstName, setFirstName] = React.useState('')
	const [lastName, setLastName] = React.useState('')
	const [address, setAddress] = React.useState('')
	const [city, setCity] = React.useState('')
	const [province, setProvince] = React.useState('')
	const [country, setCountry] = React.useState('')
	const [postalCode, setPostalCode] = React.useState('')
	const [checkoutLoading, setCheckoutLoading] = React.useState(false)

	const handleClose = () => {
		closeCurrentModal()
	}

	const handleCheckout = async () => {
		setCheckoutLoading(true)
		await addShippingInfo({
			firstName,
			lastName,
			address,
			city,
			province,
			country,
			postalCode
		})
			.then(async (response) => {
				if (response.ok) {
					await redirectToProductCheckout(cart, inStoreCredit)
				} else {
					sendErrorAlert('Failed to add shipping information')
					closeCurrentModal()
				}
			})
			.catch(() => {
				sendErrorAlert('An unexpected error occured')
				closeCurrentModal()
			})
		setCheckoutLoading(false)
	}

	const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFirstName(e.target.value)
	}

	const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLastName(e.target.value)
	}

	const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAddress(e.target.value)
	}

	const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCity(e.target.value)
	}

	const handleProvinceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setProvince(e.target.value)
	}

	const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCountry(e.target.value)
	}

	const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPostalCode(e.target.value)
	}

	return (
		modal === MODAL_TYPES.SHIPPING && (
			<div className={styles.backdrop} onClick={handleClose}>
				<div className={styles.modal} onClick={(e) => e.stopPropagation()}>
					<h2 className={styles.title}>Shipping Information</h2>
					<div className={styles.formContainer}>
						<div className={styles.row}>
							<APTextbox
								placeholder="First Name"
								className={styles.textbox}
								onChange={handleFirstNameChange}
							/>
							<APTextbox
								placeholder="Last Name"
								className={styles.textbox}
								onChange={handleLastNameChange}
							/>
						</div>
						<div className={styles.row}>
							<APTextbox
								placeholder="Address Line"
								className={styles.textboxFull}
								onChange={handleAddressChange}
							/>
						</div>
						<div className={styles.row}>
							<APTextbox
								placeholder="City"
								className={styles.textbox}
								onChange={handleCityChange}
							/>
							<APTextbox
								placeholder="Province/State"
								className={styles.textbox}
								onChange={handleProvinceChange}
							/>
						</div>
						<div className={styles.row}>
							<APTextbox
								placeholder="Country"
								className={styles.textbox}
								onChange={handleCountryChange}
							/>
							<APTextbox
								placeholder="Postal Code"
								className={styles.textbox}
								onChange={handlePostalCodeChange}
							/>
						</div>
					</div>
					<div className={styles.buttonsContainer}>
						<APButtonPrimary
							className={styles.primaryButton}
							onClick={handleCheckout}
							isLoading={checkoutLoading}
							disabled={checkoutLoading}>
							Proceed to payment
						</APButtonPrimary>
					</div>
				</div>
			</div>
		)
	)
}
