import React, { useEffect, useState } from 'react'
import styles from './Header.module.css'
import { useModalControl } from '../../Hooks/useModalControl'
import { MODAL_TYPES } from '../../Types/Modal'
import useAuth from '../../Hooks/useAuth'
import { useCart } from '../../Hooks/useCart'

// Enum for selected navigation item
enum SelectedNavItem {
	CONTACT = 'CONTACT',
	ORDERS = 'ORDERS',
	REFERRALS = 'REFERRALS',
	ACCOUNT = 'ACCOUNT',
	CART = 'CART',
	LOGIN = 'LOGIN',
	SIGNUP = 'SIGNUP',
	LOGOUT = 'LOGOUT'
}

export const Header: React.FC = () => {
	const { isLoggedIn } = useAuth()
	const { modal, switchModal } = useModalControl()
	const { redirectToCart } = useCart()
	const [selectedItem, setSelectedItem] = useState<SelectedNavItem | null>(null)

	const handleNavClick = (item: SelectedNavItem, action?: () => void) => {
		setSelectedItem(item)
		if (action) action()
	}

	const handleSignUp = () => switchModal(MODAL_TYPES.REGISTER)
	const handleLogin = () => switchModal(MODAL_TYPES.LOGIN)
	const handleContact = () => switchModal(MODAL_TYPES.CONTACT)
	const handleCart = () => redirectToCart()
	const handleLogout = () => switchModal(MODAL_TYPES.LOGOUT)

	useEffect(() => {
		if (modal === MODAL_TYPES.REGISTER) setSelectedItem(SelectedNavItem.SIGNUP)
		else if (modal === MODAL_TYPES.LOGIN) setSelectedItem(SelectedNavItem.LOGIN)
		else if (modal === MODAL_TYPES.CONTACT) setSelectedItem(SelectedNavItem.CONTACT)
		else if (modal === MODAL_TYPES.CART) setSelectedItem(SelectedNavItem.CART)
		else if (modal === MODAL_TYPES.LOGOUT) setSelectedItem(SelectedNavItem.LOGOUT)
		else if (
			selectedItem === SelectedNavItem.SIGNUP ||
			selectedItem === SelectedNavItem.LOGIN ||
			selectedItem === SelectedNavItem.CONTACT ||
			selectedItem === SelectedNavItem.CART ||
			selectedItem === SelectedNavItem.LOGOUT
		)
			setSelectedItem(null)
	}, [modal])

	const getClassName = (item: SelectedNavItem) =>
		`${styles[item.toLowerCase()]} ${selectedItem === item ? styles.selected : ''}`

	return (
		<header className={styles.header}>
			<div className={styles.topBar}>
				<a
					onClick={() => handleNavClick(SelectedNavItem.CONTACT, handleContact)}
					className={getClassName(SelectedNavItem.CONTACT)}>
					CONTACT
				</a>

				{isLoggedIn ? (
					<>
						<a
							href="orders"
							onClick={() => handleNavClick(SelectedNavItem.ORDERS)}
							className={getClassName(SelectedNavItem.ORDERS)}>
							ORDERS
						</a>
						<a
							href="referrals"
							onClick={() => handleNavClick(SelectedNavItem.REFERRALS)}
							className={getClassName(SelectedNavItem.REFERRALS)}>
							REFERRALS
						</a>
						<a
							href="account"
							onClick={() => handleNavClick(SelectedNavItem.ACCOUNT)}
							className={getClassName(SelectedNavItem.ACCOUNT)}>
							ACCOUNT
						</a>
						<a
							onClick={() => handleNavClick(SelectedNavItem.CART, handleCart)}
							className={getClassName(SelectedNavItem.CART)}>
							CART
						</a>
						<a
							onClick={() => handleNavClick(SelectedNavItem.LOGOUT, handleLogout)}
							className={getClassName(SelectedNavItem.LOGOUT)}>
							LOGOUT
						</a>
					</>
				) : (
					<>
						<a
							onClick={() => handleNavClick(SelectedNavItem.LOGIN, handleLogin)}
							className={getClassName(SelectedNavItem.LOGIN)}>
							LOGIN
						</a>
						<a
							onClick={() => handleNavClick(SelectedNavItem.SIGNUP, handleSignUp)}
							className={getClassName(SelectedNavItem.SIGNUP)}>
							SIGN UP
						</a>
					</>
				)}
			</div>
			<h1 className={styles.title}>Armoire Privée</h1>
		</header>
	)
}
