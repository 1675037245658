import { configureStore } from '@reduxjs/toolkit'
import alertReducer from './alertSlice'
import modalReducer from './modalSlice'
import accountReducer from './accountSlice'
import productInfoReducer from './productInfoSlice'
import cartReducer from './cartSlice'
import refCodeReducer from './refCodeSlice'

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        modal: modalReducer,
        account: accountReducer,
        productInfo: productInfoReducer,
        cart: cartReducer,
        refCode: refCodeReducer
    }
})