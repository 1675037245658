import { createSlice } from "@reduxjs/toolkit";

const refCodeSlice = createSlice({
    name: 'refCode',
    initialState: {
        refCode: '',
        refCodeLoading: false,
    },
    reducers: {
        setRefCode: (state, action) => {
            state.refCode = action.payload;
        },
    },
});

export const { setRefCode } = refCodeSlice.actions;
export default refCodeSlice.reducer;